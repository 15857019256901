<template>
  <div>
    <!-- Summry Card -->
    <b-row>
      <b-col
        md="12"
        lg="12"
      >
        <b-card
          text-variant="white"
          bg-variant="primary"
          align="center"
          class="position-static"
        >
          <blockquote class="blockquote  mb-0">
            <p>충전 방법</p>
            <footer class="blockquote-footer text-white opacity-75">
              <small>
                <cite title="Source Title">{{ depositNotice }}</cite>
              </small>
            </footer>
          </blockquote>
        </b-card>
      </b-col>
    </b-row>
    <div class="row">
      <div class="col-md-8 d-flex flex-column">
        <!-- 왼쪽 col 내용 -->
        <b-card class="h-100">
          <b-col cols="12">
            <b-form-group
              label="입금하실 계좌"
              label-for="hi-first-name"
              label-cols-md="4"
              class="d-flex align-items-center"
            >
              <b-button
                v-if="Object.keys(fetchDepositAccount).length === 0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="btn-sm"
                variant="outline-success"
                @click.prevent="btnAccount"
              >
                계좌 발급받기
              </b-button>
              <div
                v-else
                class="text-success"
              >
                {{ fetchDepositAccount.accountBank }} / {{ fetchDepositAccount.accountNumber }}
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="입금주"
              label-for="hi-email"
              label-cols-md="4"
              class="d-flex align-items-center"
            >
              <b-input-group class="input-group-merge">
                <div
                  v-if="fetchDepositAccount"
                  class="text-success"
                >
                  {{ fetchDepositAccount.accountName }}
                </div>
              </b-input-group>
            </b-form-group>
          </b-col>
          <hr>
          <b-col cols="12">
            <b-form-group
              label="입금자명"
              label-for="hi-number"
              label-cols-md="4"
              class="d-flex align-items-center"
            >
              <b-input-group class="input-group-merge">
                <div class="text-warning">
                  {{ this.userData.username }}
                </div>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="충전금액"
              label-for="hi-password"
              label-cols-md="4"
              class="d-flex align-items-center"
            >
              <b-input-group class="input-group-merge">
                <div class="text-warning">
                  {{ Number(depositAmt).toLocaleString() }}
                </div>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-card>
      </div>
      <div class="col-md-4 d-flex flex-column">
        <!-- 오른쪽 col 내용 -->
        <b-card class="h-100">
          <b-col class="">
            <div class="justify-content-center">
              <div class="row">
                <div
                  class="col-6"
                  style="padding-right: 2px"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block"
                    @click="depositAmt += 10000"
                  >1만</b-button>
                </div>
                <div
                  class="col-6"
                  style="padding-left: 2px"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block"
                    @click="depositAmt += 50000"
                  >5만</b-button>
                </div>
                <div
                  class="col-6"
                  style="padding-right: 2px"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block"
                    @click="depositAmt += 100000"
                  >10만</b-button>
                </div>
                <div
                  class="col-6"
                  style="padding-left: 2px"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block"
                    @click="depositAmt += 200000"
                  >20만</b-button>
                </div>
                <div
                  class="col-6"
                  style="padding-right: 2px"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block"
                    @click="depositAmt += 500000"
                  >50만</b-button>
                </div>
                <div
                  class="col-6"
                  style="padding-left: 2px"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block"
                    @click="depositAmt += 1000000"
                  >100만</b-button>
                </div>
                <div class="col-12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="btn-num btn-block font-small-3"
                    @click="depositAmt = 0"
                  >Reset</b-button>
                </div>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-warning"
                class="btn-block mt-1"
                @click="confirmDeposit()"
              >충전신청</b-button>
            </div>
          </b-col>
        </b-card>
      </div>
    </div>
    <!-- Deposit History -->
    <b-card>
      <b-row>
        <b-col cols="12">
          <!-- bs-table -->
          <b-table
            show-empty
            striped
            hover
            responsive
            class="text-center"
            empty-text="(1) 검색결과가 없습니다."
            empty-filtered-text="(2) 검색결과가 없습니다."
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :items="fetchDeposit"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="filterOn"
          >
            <template #cell(createdAt)="data">
              <i>{{ data.value | yyyyMMdd }} </i>
            </template>
            <template #cell(deposit)="data">
              <i>{{ data.value | krw | won }} </i>
            </template>
            <template #cell(type)="data">
              <b-badge :variant="type[1][data.value]">
                {{ type[0][data.value] }}
              </b-badge>
            </template>
          </b-table>
          <!-- bs-table. -->
          <!-- pagination -->
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              align="center"
              class="my-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <!-- pagination. -->
        </b-col>
      </b-row>
    </b-card>
    <div class="row">
      <div class="col-md-12 d-flex flex-column">
        <b-card>
          <div class="d-flex">
            <b-table
              :items="bankTimings1"
              hover
              :fields="fields1"
              class="small text-center border"
              style="background-color: #192336"
            >
              <template #cell(bank)="data">
                {{ data.item.bank }}
              </template>
              <template #cell(time)="data">
                {{ data.item.time }}
              </template>
            </b-table>
            <b-table
              :items="bankTimings2"
              hover
              :fields="fields1"
              class="small text-center border"
              style="background-color: #192336"
            >
              <template #cell(bank)="data">
                {{ data.item.bank }}
              </template>
              <template #cell(time)="data">
                {{ data.item.time }}
              </template>
            </b-table>
            <b-table
              :items="bankTimings3"
              hover
              :fields="fields1"
              class="small text-center border"
              style="background-color: #192336"
            >
              <template #cell(bank)="data">
                {{ data.item.bank }}
              </template>
              <template #cell(time)="data">
                {{ data.item.time }}
              </template>
            </b-table>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BInputGroup,
  BFormGroup,
  BFormCheckbox,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'

import {
  FETCH_DEPOSIT, FETCH_DEPOSIT_ACCOUNT,
} from '@/store/deposit/action'
import { FETCH_SET_DEPOSIT_EXCHANGE } from '@/store/settings/action'
import { UPSERT_REQUEST_ALARMS } from '@/store/settings/mutation'
import { INSERT_DEPOSIT, UPSERT_DEPOSIT } from '@/store/deposit/mutation'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Swal from 'sweetalert2'
import deposit from '@/store/deposit'
import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm:ss'
const fmt2 = 'YYYYMMDD'

const authStore = createNamespacedHelpers('authStore')
const depositStore = createNamespacedHelpers('depositStore')
const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
  },
  directives: {
    Ripple,
  },

  // 커스텀 필터 설정
  filters: {
    // filter로 쓸 filter ID 지정
    yyyyMMdd(value) { //  yyyy MM dd 형태로 만들어줄거기 때문에 이렇게 이름을 지었음
      // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
      if (value == '') return ''

      // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
      const js_date = new Date(value)

      // 연도, 월, 일 추출
      const year = js_date.getFullYear()
      let month = js_date.getMonth() + 1
      let day = js_date.getDate()

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if (month < 10) {
        month = `0${month}`
      }

      if (day < 10) {
        day = `0${day}`
      }

      // 최종 포맷 (ex - '2021-10-08')
      return `${year}-${month}-${day}`
    },
    krw(value) {
      if (value == '') return ''
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    won(value) {
      if (value == '') return ''
      return `${value} 원`
    },
  },
  data() {
    return {
      uid: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      userIp: localStorage.getItem('ip'),
      userInfo: {
        userid: '',
        username: '',
        nickname: '',
        cash: 0,
        point: 0,
        rank: 1,
      },
      depositAmt: 0,
      sortBy: 'idx',
      sortDesc: true,
      sortDirection: 'desc',
      fields: [
        { key: 'idx', label: 'No.' },
        { key: 'createdAt', label: '신청날짜' },
        { key: 'deposit', label: '충전금액' },
        { key: 'userid', label: '임금자명' },
        { key: 'type', label: '상태' },
      ],
      fields1: [
        { key: 'bank', label: '은행' },
        { key: 'time', label: '불가시간', thClass: 'display: false' },
      ],
      type: [{
        in: '신청', ok: '승인', reject: '부결', cancel: '취소',
      },
      {
        in: 'light-warning', ok: 'light-success', reject: 'light-primary', cancel: 'light-secondary',
      }],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      accountBank: '',
      accountNo: '',
      accountNo1: '',
      accountName: '',
      depositNotice: '',
      lastDepositTime: 0,
      bankTimings1: [
        { bank: '국민은행', time: '00:00 ~ 00:10' },
        { bank: '우리은행', time: '00:00 ~ 00:10' },
        { bank: '신한은행', time: '00:00 ~ 00:10' },
        { bank: '농협', time: '23:55 ~ 00:35' },
        { bank: '축협', time: '00:00 ~ 00:10' },
        { bank: '기업은행', time: '00:00 ~ 00:30' },
        { bank: '하나은행', time: '00:00 ~ 00:10' },
        { bank: '우체국', time: '04:00 ~ 05:00' },
        { bank: 'SC제일', time: '11:40 ~ 00:40' },
      ],
      bankTimings2: [
        { bank: '외환은행', time: '23:55 ~ 00:05' },
        { bank: '시티은행', time: '23:40 ~ 00:05' },
        { bank: '수협', time: '11:50 ~ 00:30' },
        { bank: '신협', time: '23:50 ~ 00:10' },
        { bank: '새마을금고', time: '00:00 ~ 00:30' },
        { bank: '상호저축은행', time: '23:00 ~ 08:00' },
        { bank: '경기은행', time: '23:40 ~ 00:50' },
        { bank: '경남은행', time: '23:50 ~ 00:00' },
        { bank: '광주은행', time: '00:00 ~ 00:20' },
      ],
      bankTimings3: [
        { bank: '부산은행', time: '00:00 ~ 00:05' },
        { bank: '제주은행', time: '00:00 ~ 00:10' },
        { bank: '전북은행', time: '23:50 ~ 00:05' },
        { bank: '대구은행', time: '23:50 ~ 00:50' },
        { bank: '미래에셋', time: '23:30 ~ 00:30' },
        { bank: '삼성증권', time: '23:50 ~ 00:10' },
        { bank: '한화투자', time: '01:45 ~ 02:15' },
        { bank: 'SK증권', time: '23:50 ~ 06:00' },
        { bank: '현대증권', time: '23:45 ~ 00:15' },
      ],
    }
  },

  computed: {
    ...depositStore.mapGetters([
      'fetchDeposit',
      'fetchDepositAccount',
      'fetchSetAccount',
    ]),
    ...settingsStore.mapGetters([
      'fetchSetDepositExchange',
    ]),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: JSON.parse(localStorage.getItem('userData')).userid,
      menu: 'deposit',
      menuCategory: '',
    })
    // this.userData = this.$store.getters['authStore/user']
    this.userInfo.userid = this.userData.userid
    this.userInfo.username = this.userData.username
    this.userInfo.nickname = this.userData.nickname
    this.userInfo.cash = this.userData.cash
    this.userInfo.point = this.userData.point
    this.userInfo.rank = this.userData.rank

    // ##.Deposit init
    this.fetchData()
  },
  beforeDestroy() {
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: JSON.parse(localStorage.getItem('userData')).userid,
      menu: '',
      menuCategory: '',
    })
  },
  methods: {
    ...depositStore.mapActions({
      $insertDeposit: INSERT_DEPOSIT,
      $fetchDeposit: FETCH_DEPOSIT,
      $fetchDepositAccount: FETCH_DEPOSIT_ACCOUNT,
      $upsertDeposit: UPSERT_DEPOSIT,
    }),
    ...settingsStore.mapActions({
      $upsertRequestAlarms: UPSERT_REQUEST_ALARMS,
      $fetchSetDepositExchange: FETCH_SET_DEPOSIT_EXCHANGE,
    }),
    createUid() {
      this.uid = moment(new Date())
    },
    // ##.Deposit init
    async fetchData() {
      await this.$fetchDeposit({
        site: this.$site,
        userid: this.userData.userid,
      })
      await this.$fetchSetDepositExchange({
        site: this.$site,
      })
      this.depositNotice = this.fetchSetDepositExchange.depositNotice
      this.totalRows = this.fetchDeposit.length
    },

    /* [USER] Deposit > 계좌발급 */
    async btnAccount() {
      this.$fetchDepositAccount(this.userData)
    },

    showToast(title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          variant: 'info',
        },
      })
    },
    // ##.Deposit validation
    confirmDeposit() {
      if (Object.keys(this.fetchDepositAccount).length === 0) {
        Swal.fire({
          title: '계좌 발급 후 신청 바랍니다.',
          icon: 'warning',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return false
      }

      const depositAmt = Number(this.depositAmt)
      const depositMin = Number(this.fetchSetDepositExchange.depositMin)
      const depositAmount = Number(this.fetchSetDepositExchange.depositAmount)
      const depositInterval = Number(this.fetchSetDepositExchange.depositInterval)
      const depositIntervalMs = Number(depositInterval * 60000)
      const lastArr = Number(this.fetchDeposit.length - 1)

      if (lastArr === -1) {
        this.lastDepositTime = 0
      } else {
        this.lastDepositTime = this.fetchDeposit[lastArr].createdAt
      }
      const currentTime = new Date().getTime()
      const timeDiff = currentTime - this.lastDepositTime
      if (depositAmt < depositMin) {
        this.showToast(`최소 충전금액은 ${depositMin.toLocaleString()} 원 입니다.`)
      } else if (depositAmt % depositAmount !== 0) {
        this.showToast(`${depositAmount.toLocaleString()} 원 단위로 입력해 주세요.`)
      // } else if (timeDiff < depositIntervalMs) {
      //   this.showToast(`충전 주기는 ${depositInterval} 분 입니다.`)
      } else {
        // Deposit validation : Success
        Swal.fire({
          title: '충전 신청 하시겠습니까?',
          text: Number(this.depositAmt).toLocaleString(),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Go Deposit!',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        }).then(result => {
          // Deposit Confirm
          if (result.isConfirmed) {
            this.createUid()
            this.requestDeposit()
          }
        })
      }
    },

    // ##.Deposit Save Excute
    async requestDeposit() {
      const mySite = this.userData.site
      const myId = this.userData.userid
      const ip = this.userIp
      const requestDepositData = {
        site: mySite,
        userid: myId,
        deposit: Number(this.depositAmt),
        type: 'in',
        depositType: 'sports', // 유저 라디오 버튼 연결 필요
        userBank: this.userData.accountBank,
        userAccount: this.userData.accountNumber,
        adminBank: this.fetchDepositAccount.accountBank,
        adminAccount: this.fetchDepositAccount.accountNumber,
        userIp: ip,
        uid: `${mySite}:${myId}:${this.depositAmt}:${this.uid}`,
      }
      await this.$insertDeposit({
        ...requestDepositData,
      })
      // await this.$upsertRequestAlarms({
      //   site: mySite,
      //   category: '충전',
      //   title: '충전 신청',
      //   content: `${Number(this.depositAmt).toLocaleString()}원 충전 신청`,
      //   requestId: myId,
      //   result: '대기',
      //   uid: mySite + ':' + myId + ':' + this.depositAmt + ':' + this.uid,
      // })
      // // console.log(requestDepositData)
      // this.$socket.emit('requestDeposit', {
      //   user: null,
      //   userDeposit: requestDepositData,
      // })
      Swal.fire({
        title: '충전신청완료',
        text: `${Number(this.depositAmt).toLocaleString()}원`,
        icon: 'success',
        timer: 5000,
        background: 'rgb(3,11,37)',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.fetchData()
          this.depositAmt = 0
        }
      })
    },
  },
}
</script>
<style lang="scss">
.btn-num {
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  margin-bottom: 5px;
  //box-shadow: 0 0 3px #eee;
  border-radius: 5px;
  //background-image: linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%);
  background-image: linear-gradient(to right, #2b353f 0%, #353f68 51%, #273e5f 100%);

  &:hover {
    background-position: right center; /* change the direction of the change here */
    text-align: center;
  }
}
</style>
